import React, { useState, useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import { spaceChildrenY } from '../../styles/space'
import { subTitleStyle, loginMainTitleStyle, loginSubTitleStyle } from '../../styles/text'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setLoggedIn, setLoginContinue } from '../../redux/siteSlice'
import { PASSWORD } from '../../constants/constants'
import { theme } from '../../styles/theme'
import { mq } from '../../styles/media'
import { px, py } from '../../styles/padding'
import { motion, useAnimation } from 'framer-motion'
import mikkoAvatarPng from '../../images/mikko-avatar.jpg'
import emojiWave from '../../images/emojis/emoji-wave.png'
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
import { DividerEllipseBottom } from '../Svg/DividerEllipseBottom'

function LoginTitle({ title }: { title: string }) {
  return (
    <div css={css({ display: 'flex', justifyContent: 'center' })}>
      <div css={css(loginMainTitleStyle, { position: 'relative' })}>
        {title}
        <motion.div
          initial="initial"
          whileHover="hover"
          variants={{
            initial: {
              rotate: 0,
              originX: 0.65,
              originY: 0.7,
            },
            hover: {
              rotate: 45,
              transition: { duration: 0.4, repeat: Infinity, repeatType: 'reverse' },
            },
          }}
          css={css({
            position: 'absolute',
            top: '-1em',
            right: 0,
            width: '2rem',
            height: '2rem',
            img: {
              width: '100%',
              height: 'auto',
              flex: 0,
            },
          })}
        >
          <img src={emojiWave} alt="" />
        </motion.div>
      </div>
    </div>
  )
}

export function Login() {
  const LoginWrapperRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(LoginWrapperRef, {})
  const isVisible = !!entry?.isIntersecting

  const loginContinueWrapperRef = useRef<HTMLDivElement>()
  const [loginInput, setLoginInput] = useState<string>('')

  const [loginFinished, setLoginFinished] = useState(false)

  const loginDivControl = useAnimation()
  const loggedInDivControl = useAnimation()
  const loginContinueControl = useAnimation()

  const dispatch = useAppDispatch()
  const loggedIn = useAppSelector(state => state.site.loggedIn)
  const visitorName = useAppSelector(state => state.site.visitorName)
  const loginContinue = useAppSelector(state => state.site.loginContinue)

  function loginContinueAnim() {
    if (!loginContinue) {
      loginContinueControl.start('sendMessage').then(() => {
        dispatch(setLoginContinue(true))
      })
    }
  }

  function onDragEnd(xOffset: number) {
    const parentWidth = loginContinueWrapperRef.current?.clientWidth
    if (parentWidth && xOffset >= parentWidth / 2) {
      loginContinueAnim()
    }
  }

  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value
    const passWordLength = PASSWORD.length
    if (value.length <= passWordLength) {
      setLoginInput(value)
    }
    if (value === PASSWORD) {
      dispatch(setLoggedIn(true))
    }
  }

  function onLoginContinueHandler() {
    if (!loginContinue) {
      loginContinueAnim()
    }
    // if (loginContinue) {
    //   loginContinueControl.start('initial').then(() => {
    //     dispatch(setLoginContinue(false))
    //   })
    // }
  }

  useEffect(() => {
    if (isVisible) {
      if (loginFinished) {
        loggedInDivControl.start('show')
      }
      if (!loggedIn) {
        loginDivControl.start('show')
      }
      if (loggedIn) {
        loginDivControl.start('hide').then(() => {
          setLoginFinished(true)
        })
      }
    }
  }, [loggedIn, loginFinished, isVisible])

  const loginVariants = {
    hide: {
      opacity: 0,
      transition: {
        duration: 1,
        delay: 0.6,
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        delay: 0.6,
      },
    },
  }

  const loggedInVariants = {
    hide: {
      opacity: 0,
      transition: {
        duration: 1,
      },
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 1,
        duration: 1,
      },
    },
  }

  return (
    <>
      <div
        css={css({
          minHeight: '70vh',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: theme.colors.background.gray,
          color: theme.colors.text.dark.primary,
          overflow: 'hidden',
          [mq.l]: {
            fontSize: '1.25rem',
          },
        })}
      >
        <div
          ref={LoginWrapperRef}
          css={css(
            py('2rem'),
            px('1.5rem'),
            {
              label: 'login-wrapper',
              alignSelf: 'center',
              textAlign: 'center',
              maxWidth: '40em',
            },
            spaceChildrenY(1)
          )}
        >
          {loginFinished || visitorName ? (
            <motion.div initial="hide" animate={loggedInDivControl} variants={loggedInVariants}>
              <div css={spaceChildrenY(2)}>
                <motion.div variants={{ hide: { y: -16, opacity: 0 }, show: { y: 0, opacity: 1 } }}>
                  <LoginTitle title={`Hello ${visitorName ? visitorName : 'visitor'},`} />
                  <p css={loginSubTitleStyle}>welcome to my portfolio.</p>
                </motion.div>
                <motion.div
                  variants={{ hide: { x: -16, opacity: 0 }, show: { x: 0, opacity: 1 } }}
                  css={css({
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.07)',
                    borderRadius: '1rem',
                    padding: '1rem 1.5rem',
                    textAlign: 'left',
                    position: 'relative',
                  })}
                >
                  <div
                    css={css({
                      display: 'none',
                      [mq.m]: {
                        display: 'block',
                      },
                      width: '4em',
                      height: '4em',
                      position: 'absolute',
                      top: '-3em',
                      left: '-3em',
                      img: {
                        width: '100%',
                        height: 'auto',
                        borderRadius: '2em',
                        boxShadow: '0 2px 8px hsl(0deg 0% 0% / 20%)',
                      },
                    })}
                  >
                    <img src={mikkoAvatarPng} alt="Mikko Vänskä avatar" />
                  </div>
                  <p>
                    I’m Mikko Vänskä, a designer and web developer from Helsinki. I love coming up with new ideas and
                    solving real world problems with technology.
                  </p>
                </motion.div>
                <motion.div
                  variants={{ hide: { x: 16, opacity: 0 }, show: { x: 0, opacity: 1 } }}
                  ref={loginContinueWrapperRef}
                  css={css({
                    display: 'flex',
                    justifyContent: 'flex-start',
                    '&[data-continue=true]': {
                      justifyContent: 'flex-end',
                    },
                  })}
                  data-continue={loginContinue}
                >
                  <motion.button
                    layout
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragEnd={(_, info) => onDragEnd(info.offset.x)}
                    transition={{
                      type: 'spring',
                      stiffness: 700,
                      damping: 30,
                    }}
                    css={css({
                      backgroundColor: theme.colors.background.blue,
                      // boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.07)',
                      borderRadius: '1rem',
                      padding: '1rem 1.5rem',
                      textAlign: 'left',
                      color: loginContinue ? '#FFFFFF' : '#FFFFFF80',
                      transition: 'color .3s ease-in-out',
                      cursor: 'pointer',
                      fontSize: '1em',
                      maxWidth: '12em',
                      [mq.l]: {
                        maxWidth: '16em',
                      },
                      '&:hover': {
                        color: '#FFFFFF',
                      },
                    })}
                    onClick={onLoginContinueHandler}
                  >
                    <motion.span
                      animate={loginContinue ? 'static' : 'gesture'}
                      initial="gesture"
                      variants={{
                        gesture: {
                          color: ['#FFFFFF80', '#FFFFFF', '#FFFFFF80'],
                          transition: {
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 1.8,
                          },
                        },
                        static: {
                          color: '#FFFFFF',
                        },
                      }}
                    >
                      Ok, cool. Show me what you got!
                    </motion.span>
                  </motion.button>
                </motion.div>
                <motion.div
                  animate={loginContinue ? 'show' : 'hide'}
                  variants={{
                    hide: { x: 32, opacity: 0 },
                    show: {
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.6,
                      },
                    },
                  }}
                  css={css({
                    flex: 1,
                    position: 'relative',
                    display: 'flex',
                  })}
                >
                  <div
                    css={css({
                      backgroundColor: 'white',
                      boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.07)',
                      borderRadius: '1rem',
                      padding: '1rem 1.5rem',
                      textAlign: 'left',
                    })}
                  >
                    <p
                      css={css({
                        position: 'relative',
                        marginRight: '1em',
                        span: {
                          position: 'relative',
                          width: '2em',
                          heigth: '1em',
                          div: {
                            position: 'absolute',
                            top: 0,
                            left: '0.5em',
                          },
                        },
                      })}
                    >
                      {`Sure! Scroll down for the portfolio `}
                      <span>
                        <motion.div
                          animate={{
                            y: [0, 8],
                          }}
                          transition={{
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 1,
                          }}
                        >{`👇`}</motion.div>
                      </span>
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          ) : (
            <motion.div animate={loginDivControl} initial="hide" variants={loginVariants} css={spaceChildrenY(2)}>
              <div>
                <LoginTitle title={'Hello stranger,'} />
                <p css={loginSubTitleStyle}>it seems we haven’t met before.</p>
              </div>
              <div
                css={css({
                  background: theme.colors.gradient.blue,
                  padding: '2rem',
                  color: 'white',
                  borderRadius: '1rem',
                  boxShadow: theme.colors.shadow.darkCardOnWhite,
                })}
              >
                <p css={css(subTitleStyle('light'), { marginBottom: '1rem', color: 'white' })}>
                  Enter password to enter:
                </p>
                <label>
                  <input
                    type="password"
                    placeholder={'•••••'}
                    value={loginInput}
                    onChange={onChangeHandler}
                    css={css({
                      textAlign: 'center',
                      color: 'white',
                      background: 'transparent',
                      outline: 0,
                      fontSize: '1.5em',
                      border: `2px solid ${theme.colors.text.secondary}`,
                      borderRadius: 32,
                      width: '4.25em',
                      padding: '0 1em',
                      caretColor: 'transparent',
                      backgroundImage: 'none !important',
                      userSelect: 'none',
                      '::selection': {
                        color: '#FFFFFF',
                      },
                      ':focus': {
                        borderColor: theme.colors.orange,
                      },
                    })}
                  />
                </label>
              </div>
            </motion.div>
          )}
        </div>
      </div>
      <div
        css={css({
          position: 'relative',
          paddingBottom: '4rem',
          [mq.l]: {
            paddingBottom: '8rem',
          },
          svg: {
            width: '100%',
            height: 'auto',
            position: 'absolute',
            top: '-1px',
          },
        })}
      >
        <DividerEllipseBottom />
      </div>
    </>
  )
}
